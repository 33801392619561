import React, { useState } from 'react'
import { Timeline } from 'react-event-timeline'
import PolicyTimelineEvent from './PolicyTimelineEvent'
import PropTypes from 'prop-types'
import {
  combineEventsWithDocuments,
  combineEventsWithEventDescription,
  sortByCreatedAtAscending
} from '../../../../utils/timeline/TimelineUtils'
import { ClickableText } from '../../../../auth/ClickableText'
import PolicyLinesSummary from './PolicyLinesSummary'
import { RightToolbarDialog } from '../../../../components/RightToolbarDialog'

export type PolicyEventDocument = any // TODO

export type PolicyEvent = {
  createdAt: string
  createdBy: string
  customerId: string
  effectiveDate: string | null
  eventId: string
  eventType: string
  policyId: string
  policyLines: unknown[] | null
  renewalDate: string
  type: string
  newPolicyId: string | null
}

export type PolicyEventTypeDescriptor = {
  type: string
  subType?: string
  description: string
}

function claimEventTypes() {
  return [
    {
      type: 'CLAIM_CREATED',
      description: 'Schaden erstellt'
    },
    {
      type: 'CLAIM_CLOSED',
      description: 'Schaden geschlossen'
    },
    {
      type: 'CLAIM_DECLINED',
      description: 'Schaden abgelehnt'
    },
    {
      type: 'RESERVE_ALLOCATED',
      description: 'Reservestellung'
    },
    {
      type: 'RESERVE_CHANGED',
      description: 'Reserve geändert'
    },
    {
      type: 'CLAIM_3RD_PARTY_ADDED',
      description: 'Geschädigte Person erfasst'
    },
    {
      type: 'CLAIM_PREMIUM_PAYMENT_CHECK_COMPLETED',
      description: 'Formelle Deckung geprüft'
    },
    {
      type: 'POLICY_COVERAGE_CHECK_COMPLETED',
      description: 'Versicherungszeitraum für Schaden geprüft'
    },
    {
      type: 'CLAIM_MATERIAL_CHECK_COMPLETED',
      description: 'Materielle Deckung geprüft'
    },
    {
      type: 'PAYMENT_CREATED',
      description: 'Schadenzahlung geleistet'
    },
    {
      type: 'CLAIM_REOPENED',
      description: 'Schaden erneut geöffnet'
    }
  ]
}

function bonusEventTypes(): PolicyEventTypeDescriptor[] {
  return [
    {
      type: 'BONUS_CREATED',
      subType: 'NoClaimBonusCreated',
      description: 'Schadenfreiheitsbonus erstellt'
    },
    {
      type: 'BONUS_CREATED',
      subType: 'GoalBonusCreated',
      description: 'Torprämie erstellt'
    }
  ]
}

function scheduledEventTypes(): PolicyEventTypeDescriptor[] {
  return [
    {
      type: 'SCHEDULED_CANCELLATION',
      description: 'Kündigung der Police geplant'
    }
  ]
}

export default function PolicyTimeline({
  events,
  documents,
  eventTypes = [],
  testData,
  transformationConfig,
  ...noneEventProps
}: {
  events: PolicyEvent[]
  documents: PolicyEventDocument[]
  eventTypes: PolicyEventTypeDescriptor[]
  testData: boolean
  transformationConfig: any
}) {
  const [openEvents, setOpenEvents] = useState<PolicyEvent[]>([])

  eventTypes = eventTypes.concat(
    claimEventTypes(),
    bonusEventTypes(),
    scheduledEventTypes()
  )

  const sortedEvents = events
    .sort(sortByCreatedAtAscending)
    .filter(removeIgnoredEvents)
    .reverse()

  const eventsWithDocuments = combineEventsWithDocuments(
    sortedEvents,
    documents
  )
  const eventsWithDocumentsAndEventDescription =
    combineEventsWithEventDescription(eventsWithDocuments, eventTypes)

  if (transformationConfig) {
    transformationConfig.eventId = 'transformationConfig'
    transformationConfig.eventDescription = 'Transformation Configuration'
    transformationConfig.createdBy = 'products-service'
  }
  return (
    <div className="policy-timeline-wrapper">
      <CompareEventDialogButton openEvents={openEvents} />
      <Timeline>
        <>
          {transformationConfig && (
            <PolicyTimelineEvent
              key={'transformationConfig'}
              event={transformationConfig}
              attachments={[]}
              testData={testData}
              onEventOpened={() =>
                setOpenEvents(oe => [...oe, transformationConfig])
              }
              onEventClosed={() =>
                setOpenEvents(oe =>
                  oe.filter(e => e.eventId !== transformationConfig.eventId)
                )
              }
              eventList={eventsWithDocumentsAndEventDescription}
              isLatestEvent={true}
              data-testid="policy-timeline-event"
              {...noneEventProps}
            />
          )}
          {eventsWithDocumentsAndEventDescription.map(event => (
            <PolicyTimelineEvent
              key={event.eventId}
              event={event}
              attachments={event.attachments}
              testData={testData}
              onEventOpened={() => setOpenEvents(oe => [...oe, event])}
              onEventClosed={() =>
                setOpenEvents(oe => oe.filter(e => e.eventId !== event.eventId))
              }
              eventList={eventsWithDocumentsAndEventDescription}
              isLatestEvent={event.eventId === sortedEvents[0].eventId}
              data-testid="policy-timeline-event"
              {...noneEventProps}
            />
          ))}{' '}
        </>
      </Timeline>
    </div>
  )
}
function CompareEventDialogButton({ openEvents }) {
  const [open, setOpen] = useState(false)

  const sortedEvents = [...openEvents].sort(sortByCreatedAtAscending)
  const previousEvent = sortedEvents[0]
  const newEvent = sortedEvents[1]

  return hasTwoOpenEvents(sortedEvents) ? (
    <div>
      <ClickableText
        variant={'contained'}
        onClick={() => setOpen(true)}
        style={{
          float: 'right',
          position: 'relative',
          bottom: 25,
          right: 6
        }}>
        Ereignisse vergleichen
      </ClickableText>

      <RightToolbarDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Ereignisse vergleichen">
        <h4>Unterschiede</h4>
        <PolicyLinesSummary
          policyLines={newEvent.policyLines}
          previousPolicyLines={previousEvent.policyLines}
        />
      </RightToolbarDialog>
    </div>
  ) : null
}

function hasTwoOpenEvents(openEvents) {
  return openEvents.length === 2
}

PolicyTimeline.propTypes = {
  events: PropTypes.array.isRequired,
  documents: PropTypes.array.isRequired,
  eventTypes: PropTypes.array.isRequired
}

function removeIgnoredEvents(event) {
  return (
    event.eventType !== 'CLAIM_ITEM_ADDED' &&
    !(
      event.eventType === 'SCHEDULED_CANCELLATION' &&
      event.cancellationStatus === 'DONE'
    )
  )
}
