import React, { Component, useContext } from 'react'
import { Form, useField } from 'react-final-form'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DateTime } from 'luxon'
import { policyService } from '../../../services/PolicyService'
import ProgressVisualizer from '../../../utils/ProgressVisualizer'
import { InputLabel } from '@material-ui/core'
import store from '../../CustomerStore'
import ToggleField from '../../../components/ToggleField'
import { WhenFieldChanges } from '../../../utils/FieldRules'
import { formatDateTimeForGermany } from '../../../utils/Dates'
import { InternalPolicyContext } from './InternalPolicyContext'
import * as Validators from '../../../utils/Validators'
import SelectField from '../../../components/SelectField'
import NativeDayPickerField from '../../../components/coverage-pickers/NativeDayPickerField'
import Conflicts from '../../../components/Conflicts'

export class CancelPolicyButton extends Component {
  static propTypes = {
    caption: PropTypes.string,
    policy: PropTypes.object.isRequired,
    cancelAtCoverStart: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    highlighted: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      dialogOpen: false,
      cancellationSources: [],
      cancellationReasons: [],
      cancellationReasonsForCancelAtCoverStart: []
    }
  }

  componentDidMount() {
    this.loadCancellationReasons()
    this.loadCancellationReasonsForCancelCoverStart()
    this.loadCancellationSources()
  }

  loadCancellationReasons() {
    policyService.getCancellationReasons().then(reasons => {
      this.setState({
        cancellationReasons: reasons.reasons
      })
    })
  }

  loadCancellationSources() {
    policyService.getCancellationSources().then(sources => {
      this.setState({
        cancellationSources: sources.sources
      })
    })
  }

  loadCancellationReasonsForCancelCoverStart() {
    this.setState({
      cancellationReasonsForCancelAtCoverStart: [
        'REVOCATION',
        'FALSE_DECLARATION',
        'OTHERS'
      ]
    })
  }
  openDialog = () => {
    this.setState({ dialogOpen: true })
  }

  handleDialogCancel = () => {
    this.setState({ dialogOpen: false })
  }

  cancelPolicy = async formData => {
    try {
      if (this.props.cancelAtCoverStart) {
        await policyService.revokePolicy(
          this.props.policy,
          formData.cancellationSource,
          formData.cancellationReason
        )
      } else {
        await policyService.cancelPolicy(
          this.props.policy,
          formData.cancelDate,
          formData.cancellationSource,
          formData.cancellationReason,
          formData.cancellationRequestDate
        )
      }
    } catch (e) {
      if (!e.ok) {
        return { cancelDate: e.data.message }
      }
    }

    this.setState({ dialogOpen: false })
    store.loadCustomer(this.props.policy.customerId)
    return {}
  }

  validateValues = values => {
    if (this.props.cancelAtCoverStart) {
      return {}
    }

    const { cancelDate } = values

    if (cancelDate === null) {
      return { cancelDate: 'Bitte Kündigungsdatum angeben' }
    }

    const cancel = DateTime.fromISO(cancelDate)
    const coverStart = DateTime.fromISO(this.props.policy.coverStart)

    if (cancel.hasSame(coverStart, 'millisecond')) {
      return {
        cancelDate:
          'Zu dem Datum kann nur storniert werden, bitte die andere Schaltfläche benutzen'
      }
    }

    if (cancel < coverStart) {
      return {
        cancelDate:
          'Gewähltes Kündigungsdatum liegt vor dem Versicherungsbeginn'
      }
    }

    return {}
  }

  getColor = () => {
    if (this.props.highlighted) {
      return { color: '#404040' }
    }
    return {}
  }

  render() {
    const { caption, policy, cancelAtCoverStart, isReadOnly } = this.props

    return (
      <Form
        onSubmit={this.cancelPolicy}
        initialValues={{
          cancelDate: cancelAtCoverStart
            ? DateTime.fromISO(policy.coverStart).toISODate()
            : null
        }}
        validate={this.validateValues}
        render={({
          handleSubmit,
          invalid,
          values,
          form: { reset },
          submitting,
          hasSubmitErrors,
          dirtySinceLastSubmit
        }) => (
          <form
            data-testid="cancelPolicyForm"
            onSubmit={handleSubmit}
            style={{ display: 'inline', marginLeft: 16 }}>
            <Button
              style={this.getColor()}
              variant="text"
              color="secondary"
              data-testid="openDialogButton"
              name="openDialogButton"
              onClick={this.openDialog}
              disabled={isReadOnly}>
              {caption}
            </Button>

            <Dialog open={this.state.dialogOpen} maxWidth="md">
              <DialogTitle>{caption}</DialogTitle>

              <DialogContent style={{ paddingTop: 8 }}>
                {submitting ? (
                  <ProgressVisualizer />
                ) : (
                  <FormContent
                    cancellationSources={this.state.cancellationSources}
                    cancellationReasons={this.state.cancellationReasons}
                    cancellationReasonsForCancelAtCoverStart={
                      this.state.cancellationReasonsForCancelAtCoverStart
                    }
                    policy={policy}
                    values={values}
                    hasSubmitErrors={hasSubmitErrors}
                    dirtySinceLastSubmit={dirtySinceLastSubmit}
                    invalid={invalid}
                    cancelAtCoverStart={cancelAtCoverStart}
                    conflicts={this.state.conflicts}
                    setConflicts={c =>
                      this.setState({
                        ...this.state,
                        conflicts: c
                      })
                    }
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  name="cancelButton"
                  label="Cancel"
                  variant="contained"
                  onClick={() => {
                    this.handleDialogCancel()
                    reset()
                  }}>
                  Abbrechen
                </Button>

                <Button
                  name="okButton"
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={submitting}>
                  Bestätigen
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      />
    )
  }
}

function CancellationSource({ sources }) {
  return (
    <>
      <p>
        <InputLabel htmlFor="cancellationSource">Source</InputLabel>
      </p>

      <SelectField
        name="cancellationSource"
        style={{ fontSize: 16 }}
        validate={Validators.required}>
        {sources.map((source, key) => (
          <option key={key} value={source}>
            {source}
          </option>
        ))}
      </SelectField>
    </>
  )
}

function CancellationReason({ reasons }) {
  return (
    <>
      <p>
        <InputLabel htmlFor="cancellationReason">Reason</InputLabel>
      </p>

      <SelectField
        name="cancellationReason"
        style={{ fontSize: 16 }}
        validate={Validators.required}>
        {reasons.map((reason, key) => (
          <option key={key} value={reason}>
            {reason}
          </option>
        ))}
      </SelectField>
    </>
  )
}

function FormContent({
  policy,
  values,
  cancellationSources,
  cancellationReasons,
  cancellationReasonsForCancelAtCoverStart,
  hasSubmitErrors,
  dirtySinceLastSubmit,
  invalid,
  cancelAtCoverStart
}) {
  const { internalPolicy } = useContext(InternalPolicyContext)
  const cancelDateField = useField('cancelDate')
  const cancelDate = cancelDateField.input.value
  const submitError = cancelDateField.meta.submitError
  const content = cancelAtCoverStart ? (
    <>
      <CancellationSource sources={cancellationSources} />
      <CancellationReason reasons={cancellationReasonsForCancelAtCoverStart} />

      <p>Der Vertrag wird zum Beginn der Laufzeit storniert:</p>
      <p>{formatDateTimeForGermany(internalPolicy.coverStartDateTime)} Uhr</p>

      {(hasSubmitErrors || invalid) && (
        <div style={{ color: 'red' }}>{submitError}</div>
      )}

      <Conflicts
        effectiveDate={DateTime.fromISO(cancelDate)}
        policyId={policy.id}
      />
    </>
  ) : (
    <>
      <CancellationSource sources={cancellationSources} />
      <CancellationReason
        reasons={cancellationReasons.filter(
          reason => reason !== 'FALSE_DECLARATION' && reason !== 'REVOCATION'
        )}
      />

      <div>
        {values.cancellationSource === 'POLICY_HOLDER' ? (
          <div>
            <p>
              <InputLabel htmlFor="cancellationRequestDate">
                Anforderungsdatum
              </InputLabel>
            </p>

            <NativeDayPickerField
              name="cancellationRequestDate"
              validate={Validators.required}
              style={{ width: 200 }}
            />
          </div>
        ) : (
          <p></p>
        )}
      </div>
      <p>
        <InputLabel htmlFor="cancelDate">Kündigungsdatum</InputLabel>
      </p>

      <NativeDayPickerField
        name="cancelDate"
        validate={Validators.required}
        style={{ width: 200 }}
      />

      <WhenFieldChanges
        field="cancelAtCoverEnd"
        becomes={true}
        set="cancelDate"
        to={DateTime.fromISO(policy.coverage.coverEndDate)
          .plus({ days: 1 })
          .toISODate()}
      />

      <WhenFieldChanges
        field="cancelAtCoverEnd"
        becomes={false}
        set="cancelDate"
        to={null}
      />

      <ToggleField
        name="cancelAtCoverEnd"
        label="Zur nächsten Hauptfälligkeit kündigen"
      />

      {values.cancelDate &&
        (!hasSubmitErrors || dirtySinceLastSubmit) &&
        !invalid && (
          <div>
            <p>Die Deckung läuft zum folgenden Zeitpunkt ab:</p>
            <p>
              {DateTime.fromISO(values.cancelDate)
                .minus({ days: 1 })
                .endOf('day')
                .toLocaleString(DateTime.DATETIME_MED)}{' '}
              Uhr
            </p>
          </div>
        )}
      <div>
        <Conflicts
          effectiveDate={DateTime.fromISO(cancelDate)}
          policyId={policy.id}
        />
      </div>
    </>
  )

  return <>{content}</>
}
