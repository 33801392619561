import React from 'react'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'

import PolicyDetailsContainer from './policy-details/PolicyDetailsContainer'
import CommunicationLog from './communication-log/CommunicationLog'
import CustomerData from './CustomerData'
import { PolicyUpdateContextProvider } from '../../policy-update/PolicyUpdateContextProvider'
import { AccessRights, useIsAuthorized } from '../../auth/AuthorizationFilter'
import { Chip } from '@material-ui/core'
import CustomerContext from './CustomerContext'
import Copyable from '../../utils/Copyable'
import { InternalPolicyProvider } from './policy-details/InternalPolicyProvider'
import CustomerOrders from './customer-orders/CustomerOrders'

export function CustomerDetails({
  store,
  customerEventTypes,
  policyEventTypes
}) {
  const { isReadOnly, isInVodafoneGroupInsurance } =
    React.useContext(CustomerContext)

  const canAccessProductionData = useIsAuthorized(
    AccessRights.ProductionCustomer
  )

  const {
    selectedCustomer: { testData },
    selectedCustomer: customer,
    partner,
    documents,
    shadowFlowDocuments,
    messages,
    policies,
    loadMessageError
  } = store
  if (!customer) {
    return null
  }

  if (!testData && !canAccessProductionData && !isReadOnly) {
    return <div>Unauthorized.</div>
  }

  const CustomerDataHeader = () => {
    return (
      <React.Fragment>
        <div style={{ display: 'inline-block' }}>
          <Typography variant="h5" component="h2">
            {customer.name} {<Copyable>{customer.id}</Copyable>} -{' '}
            {partner.name} {customer.testData ? ' - Testkunde' : ''}
          </Typography>
          {isInVodafoneGroupInsurance && (
            <Chip
              color="secondary"
              label="Versicherte Person in der Vodafone Gruppenversicherung."
              style={{ margin: '1em' }}
            />
          )}
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CustomerDataHeader />

      <CustomerData
        customer={customer}
        documents={documents}
        customerEventTypes={customerEventTypes}
        isReadOnly={isReadOnly}
      />

      {policies.map(policy => {
        return (
          <InternalPolicyProvider forPolicyId={policy.id} key={policy.id}>
            {({ internalPolicy }) => {
              //lets build a nice view model to base our ui on
              const policyDetailsViewModel = {
                // take everything from the internalPolicy model via GET /policies/456
                // to get the fullCoverage object
                // to be able to excess coverage expiration prices
                // this has policyLines
                ...internalPolicy,
                // and all from the public policy response via GET /customers/123/policies
                // plus the stuff which is attached by the mobx store like the events
                // this has elements
                events: policy.events,
                paymentHistory: policy.paymentHistory,
                elements: policy.elements,
                paymentMethod: policy.paymentMethod,
                coverStart: policy.coverStart,
                coverEnd: policy.coverEnd,
                transformationConfig: policy.transformationConfig
              }

              return (
                <PolicyUpdateContextProvider internalPolicy={internalPolicy}>
                  <PolicyDetailsContainer
                    key={policy.id}
                    customer={customer}
                    policy={policyDetailsViewModel}
                    testData={customer.testData}
                    documents={documents}
                    shadowFlowDocuments={shadowFlowDocuments}
                    policyEventTypes={policyEventTypes}
                    isReadOnly={isReadOnly}
                  />
                </PolicyUpdateContextProvider>
              )
            }}
          </InternalPolicyProvider>
        )
      })}

      <CustomerOrders customerId={customer.id} />

      <CommunicationLog
        messages={messages}
        documents={documents}
        policies={policies}
        customerMessagesError={loadMessageError}
      />
    </React.Fragment>
  )
}

export default observer(CustomerDetails)
